<template>
  <div class="account-header">
    <text-logo />

    <account-settings />
  </div>
</template>

<style lang="scss">
  .account-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
</style>
