<template>
  <div class="account-settings">
    <nuxt-link class="account-settings__link icon-link" :to="ordersLink">
      <icon class="account-settings__link-icon" name="fa6-solid:file-invoice-dollar" />
    </nuxt-link>
    <nuxt-link class="account-settings__link icon-link" :to="userLink">
      <icon class="account-settings__link-icon" name="line-md:account" />
    </nuxt-link>
  </div>
</template>

<script setup>
  import { ROUTES } from '/lib/constants/routes'

  const localePath = useLocalePath()

  const ordersLink = localePath(ROUTES.ACCOUNT_ORDERS)
  const userLink = localePath(ROUTES.ACCOUNT_USER)
</script>

<style lang="scss">
  .account-settings {
    display: flex;
  }

  .account-settings__link:not(:first-of-type) {
    margin: 0 0 0 8px;

    @include breakpoint(desktop) {
      margin: 0 0 0 24px;
    }
  }

  .account-settings__link-icon {
    font-size: 24px;
    color: var(--color-lightest);
  }

  .account-settings__link.router-link-exact-active .account-settings__link-icon {
    color: var(--color-secondary);
  }
</style>
