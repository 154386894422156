<template>
  <div class="account-navigation">
    <nuxt-link
      v-if="userStore.isAdmin"
      class="account-navigation__link"
      :to="adminLink"
    >
      Admin
    </nuxt-link>
    <nuxt-link class="account-navigation__link" :to="eventsLink">
      {{ $t('events') }}
    </nuxt-link>
    <nuxt-link class="account-navigation__link" :to="connectorsLink">
      {{ $t('connectors') }}
    </nuxt-link>
    <nuxt-link class="account-navigation__link" :to="helpLink">
      {{ $t('help') }}
    </nuxt-link>
  </div>
</template>

<script setup>
  import { ROUTES } from '~/lib/constants/routes'
  import { useUserStore } from '~/stores/user'

  const localePath = useLocalePath()
  const userStore = useUserStore()

  const adminLink = localePath(ROUTES.ADMIN)
  const eventsLink = localePath(ROUTES.ACCOUNT_EVENTS)
  const connectorsLink = localePath(ROUTES.ACCOUNT_CONNECTORS)
  const helpLink = localePath(ROUTES.ACCOUNT_HELP)
</script>

<style lang="scss">
  .account-navigation {
    display: flex;
    padding: 0 0 4px;
    border-bottom: 2px solid var(--color-lightest-50);
  }

  .account-navigation__link {
    display: flex;
    color: var(--color-lightest);
  }

  .account-navigation__link:not(:first-of-type) {
    margin: 0 0 0 24px;

    @include breakpoint(desktop) {
      margin: 0 0 0 48px;
    }
  }

  .account-navigation__link.router-link-active {
    position: relative;
    font-weight: 500;
  }

  .account-navigation__link.router-link-active:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -6px;
    height: 2px;
    width: 100%;
    background: var(--color-secondary);
  }
</style>
