<template>
  <client-only>
    <div class="dashboard-layout">
      <div class="dashboard-layout__header full-width-primary-bg">
        <div class="dashboard-layout__header-content">
          <account-header class="dashboard-layout__account-header" />
          <account-menu class="dashboard-layout__account-menu" />
        </div>
      </div>
      <div class="dashboard-layout__content">
        <slot />
      </div>

      <app-footer class="dashboard-layout__footer" />

      <app-toast-wrapper />
      <app-overlay />
    </div>
  </client-only>
</template>

<script setup>
  const { t: $t } = useI18n()

  const pageTitle = computed(() => $t('seo.account_page.title'))
  const pageDescription = computed(() => $t('seo.account_page.description'))

  useHead(
    {
      title: pageTitle,
      meta: [
        { hid: 'description', name: 'description', content: pageDescription },
      ],
    }
  )
</script>

<style lang="scss">
  :root {
    --dashboard-layout-padding: 24px;
  }

  .dashboard-layout {
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
  }

  .dashboard-layout__header-content {
    padding: 0 var(--dashboard-layout-padding);
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    margin: 0 auto;
    width: 100%;
  }

  .dashboard-layout__account-header {
    padding: 12px 0;

    @include breakpoint(desktop) {
      padding: 24px 0;
    }
  }

  .dashboard-layout__account-menu {
    padding: 12px 0;

    @include breakpoint(desktop) {
      padding: 36px 0;
    }
  }

  .dashboard-layout__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    max-width: 1280px;
    margin: 75px auto 0;
    padding: 0 var(--dashboard-layout-padding) 32px;

    @include breakpoint(desktop) {
      flex-direction: row;
    }
  }
</style>
