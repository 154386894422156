<template>
  <div class="account-menu">
    <div class="account-menu__welcome">
      <h2 class="account-menu__welcome-title">
        {{ welcomeTitle }}
      </h2>
      <span class="account-menu__welcome-subtitle">
        {{ $t('account.dashboard.subtitle') }}
      </span>
    </div>

    <account-navigation class="account-menu__navigation" />
  </div>
</template>

<script setup>
  import { storeToRefs } from 'pinia'
  import { useUserStore } from '~/stores/user'

  const { t: $t } = useI18n()
  const userStore = useUserStore()

  const { user } = storeToRefs(userStore)

  const welcomeTitle = computed(() => {
    return user.value?.displayName
      ? $t('welcome') + ' ' + user.value.displayName
      : $t('welcome')
  })
</script>

<style lang="scss">
  .account-menu {
    display: flex;
    flex-direction: column;
    background: var(--color-primary);

    @include breakpoint(desktop) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .account-menu__welcome-title {
    font-size: 28px;
    color: var(--color-lightest);

    @include breakpoint(desktop) {
      font-size: 38px;
    }
  }

  .account-menu__welcome-subtitle {
    color: var(--color-lightest-50);
  }

  .account-menu__navigation {
    margin: 24px 0 0;

    @include breakpoint(desktop) {
      margin: 0;
    }
  }
</style>
